import { ActionFunctionArgs, LoaderFunctionArgs, json } from "@remix-run/node"; // or cloudflare/deno
import { useEffect, useRef } from "react";
import {
  ClientActionFunctionArgs,
  Form,
  Link,
  useActionData,
  useLoaderData,
  useNavigation,
  useSearchParams,
} from "@remix-run/react";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import i18nextServer from "~/i18next.server";
import { getDefaultServerLoaderData } from "../helpers.server";
import { getNextStepPath, getTranslations, isEmbedded, parseClientSubmissionErrors } from "./helpers";
import { createReturnOrder, fetchLatestReturnOrder } from "./api";
import { cacheEcomOrder } from "~/apis/cacheEcomOrder";
import { ActionResponse, DefaultShopWithTranslationsLoaderResponse, ValidationErrors } from "./types";
import { isMobile } from "react-device-detect";
import { ErrorBanner, LoadingSpinner, GlassCard, ShopLogo, BackgroundImage } from "~/components";
import * as Sentry from "@sentry/remix";
import { storage } from "~/storage.server";
import { getReturnOrderAuthCacheKey, getReturnOrderLocaleCacheKey } from "~/helpers/cacheKeys.server";
import { minutesToSeconds } from "date-fns";

export const clientAction = async ({ serverAction }: ClientActionFunctionArgs) => {
  let data;
  try {
    data = await serverAction();
  } catch (error) {
    if (error instanceof TypeError && error.message === "Load failed") {
      Sentry.captureException(error, { tags: { file: "CustomerInfoRoute.tsx" } });
      return { clientSubmissionErrors: "Load failed" };
    } else if (error instanceof Error) {
      Sentry.captureException(error);
      return { clientSubmissionErrors: error.message };
    } else {
      Sentry.captureException(error);
      return { clientSubmissionErrors: "Unknown error" };
    }
  }
  return data;
};

export const action = async ({ request }: ActionFunctionArgs) => {
  try {
    const formData = await request.formData();
    const shopId = String(formData.get("shopId"));
    const orderId = String(formData.get("orderId"));
    const emailOrPhone = String(formData.get("emailOrPhone"));
    const locale = String(formData.get("locale"));
    const v2CustomerFlowEnabled = formData.get("v2CustomerFlowEnabled") === "true";
    const mobile = formData.get("mobile") === "true";
    const t = await i18nextServer.getFixedT(locale);
    const validationErrors: ValidationErrors = {};

    if (!orderId) {
      validationErrors.orderId = t("customerInfo.errors.orderId");
    }
    if (!emailOrPhone) {
      validationErrors.emailOrPhone = t("customerInfo.errors.emailOrPhone");
    }
    if (Object.keys(validationErrors).length > 0) {
      return json({ validationErrors });
    }

    // this handles the check if the ecom order exists or not
    // even if there is no latest return order, but if the ecom order is found, it will return true
    try {
      let response = await fetchLatestReturnOrder(shopId, orderId, emailOrPhone, locale);
      if (!response.returnOrder) {
        try {
          response = await createReturnOrder(shopId, orderId, emailOrPhone, locale);
        } catch (error) {
          // @ts-ignore
          return json({ submissionErrors: `Error creating return order: ${error?.data?.errors ?? error.message}` });
        }
      }
      // Asynchronously cache the credentials and locale for this return order for 30 minutes.
      // The cached credentials and locale will be used in every other return order pages.
      const returnOrderId = response.returnOrder.id;
      storage.setItems(
        [
          {
            key: getReturnOrderAuthCacheKey(returnOrderId, shopId, orderId, emailOrPhone),
            value: JSON.stringify({ success: true }),
          },
          { key: getReturnOrderLocaleCacheKey(returnOrderId, shopId), value: locale },
        ],
        { ttl: minutesToSeconds(30) },
      );

      const nextStepURL = getNextStepPath({
        request,
        returnOrderResponse: response,
        orderId,
        emailOrPhone,
        locale,
        v2CustomerFlowEnabled,
        mobile,
      });

      return json({ nextStepURL });
    } catch (error) {
      // @ts-ignore
      return json({ submissionErrors: error?.data?.errors ?? error.message });
    }
  } catch (error) {
    // @ts-ignore
    Sentry.captureMessage("Error from customer info page", { extra: { error: error.message } });
    // @ts-ignore
    return json({ submissionErrors: error.message });
  }
};

export const loader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<DefaultShopWithTranslationsLoaderResponse> => {
  const defaultLoaderData = await getDefaultServerLoaderData(params, request);
  const { shopData, locale, t } = defaultLoaderData;
  const translations = getTranslations(shopData.userInterfaceSetting, locale, t);

  return { ...defaultLoaderData, translations };
};

export default function CustomerInfo() {
  const actionData = useActionData<ActionResponse>();
  const { shopData, translations, locale } = useLoaderData<typeof loader>();
  const { state } = useNavigation();
  const formRef = useRef<HTMLFormElement>(null);
  const { id: shopId, allowGiftReturns, returnPolicyLink, v2CustomerFlowEnabled } = shopData;

  const {
    validationErrors,
    submissionErrors,
    clientSubmissionErrors: clientSubmissionErrorsBeforeParsing,
    nextStepURL,
  } = actionData || {};

  const clientSubmissionErrors = parseClientSubmissionErrors(translations, clientSubmissionErrorsBeforeParsing);

  const isSubmitting = state == "submitting";
  const startButtonDisabled = isSubmitting || state == "loading" || !!nextStepURL;

  const [searchParams] = useSearchParams();

  // redirect response from backend does not work, so we have to do it here
  useEffect(() => {
    // only redirect once the form is submitted and the state is idle (when all loaders are done)
    if (state !== "idle" || !nextStepURL) return;

    if (isMobile && isEmbedded(window.location.href)) {
      window.top!.location.href = nextStepURL;
    } else {
      window.location.href = nextStepURL;
    }
  }, [nextStepURL, state]);

  const {
    orderIdLabel,
    orderIdPlaceholder,
    orderEmailPhoneLabel,
    orderEmailPhonePlaceholder,
    startButtonName,
    agreement,
    returnsPolicy,
    returningAGift,
  } = translations;

  async function handleOrderNameBlur(e: React.FocusEvent<HTMLInputElement>): Promise<void> {
    // Call backend to cache all info related to an order right after the
    // customer insert the order name and focus away from the order name field on frontend.
    e.target.value = e.target.value.trim();
    const orderName = e.target.value;

    if (orderName.length > 0) {
      try {
        await cacheEcomOrder(shopId, orderName);
      } catch {}
    }
  }

  return (
    <BackgroundImage displayLocaleSelector displayBranding>
      <ErrorBanner
        title={searchParams.get("error_title") ?? undefined}
        description={clientSubmissionErrors ?? submissionErrors ?? searchParams.get("error_message")}
        className="max-w-xl"
        position="absolute"
      />
      <div className="h-full flex justify-center items-center">
        <GlassCard>
          <ShopLogo className="absolute top-[-6rem]" />
          <Form method="post" className="w-full" ref={formRef}>
            <input type="hidden" name="shopId" value={shopId} />
            <input type="hidden" name="locale" value={locale} />
            <input type="hidden" name="v2CustomerFlowEnabled" value={v2CustomerFlowEnabled?.toString() ?? "false"} />
            <input type="hidden" name="mobile" value={isMobile.toString()} />
            <div className="mb-3">
              <Label htmlFor="orderId">{orderIdLabel}</Label>
              <Input
                variant="glass"
                className="mt-1"
                name="orderId"
                onBlur={handleOrderNameBlur}
                placeholder={orderIdPlaceholder}
              />
              {validationErrors?.orderId && (
                <p className="text-[0.8rem] font-medium text-destructive mt-2">{validationErrors.orderId}</p>
              )}
            </div>

            <div className="mb-3">
              <Label htmlFor="emailOrPhone">{orderEmailPhoneLabel}</Label>
              <Input
                variant="glass"
                className="mt-1"
                name="emailOrPhone"
                onBlur={(e) => (e.target.value = e.target.value.trim())}
                placeholder={orderEmailPhonePlaceholder}
              />
              {validationErrors?.emailOrPhone && (
                <p className="text-[0.8rem] font-medium text-destructive mt-2">{validationErrors.emailOrPhone}</p>
              )}
            </div>

            <Button className="w-full my-1" type="submit" disabled={!!startButtonDisabled}>
              {startButtonDisabled ? <LoadingSpinner /> : startButtonName}
            </Button>

            <p className="font-light text-xs mt-1">
              {agreement}{" "}
              <a href={returnPolicyLink} rel="noopener noreferrer" target="_blank" className="underline">
                {returnsPolicy}
              </a>
              .
            </p>

            {allowGiftReturns && (
              <Link to={`gift_returns/start?locale=${locale}`} prefetch="intent">
                <Button variant="link" className="w-full my-1 justify-start pl-0 pt-9 underline" type="button">
                  {returningAGift}
                </Button>
              </Link>
            )}
          </Form>
        </GlassCard>
      </div>
    </BackgroundImage>
  );
}
