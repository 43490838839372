import camelCase from "camelcase";
import { getPublicEnv } from "~/helpers";
import { UserInterfaceSetting } from "../types";
import { TFunction } from "i18next";
import { ReturnOrderResponse, ShopTranslations } from "./types";
import { UserInterfaceTranslations } from "~/types/shopData";

export const parseClientSubmissionErrors = (translations: ShopTranslations, clientSubmissionErrors?: string) => {
  if (clientSubmissionErrors === "Load failed") {
    return translations.typeError.loadFailed;
  } else {
    return clientSubmissionErrors;
  }
};

export const getText = (text: UserInterfaceTranslations | string, locale: string, defaultText: string = "") => {
  switch (typeof text) {
    case "object":
      return text[camelCase(locale)] || defaultText; // title object from rails
    default:
      return defaultText; // 360 defaults
  }
};

export function getTranslations(
  userInterfaceSetting: UserInterfaceSetting,
  locale: string,
  t: TFunction,
): ShopTranslations {
  const {
    orderIdLabelTranslations,
    orderIdPlaceholderTranslations,
    orderEmailPhoneLabelTranslations,
    orderEmailPhonePlaceholderTranslations,
    startButtonNameTranslations,
  } = userInterfaceSetting;

  return {
    orderIdLabel: getText(orderIdLabelTranslations, locale, t("customerInfo.orderId")),
    orderIdPlaceholder: getText(orderIdPlaceholderTranslations, locale),
    orderEmailPhoneLabel: getText(orderEmailPhoneLabelTranslations, locale, t("customerInfo.emailOrPhone")),
    orderEmailPhonePlaceholder: getText(orderEmailPhonePlaceholderTranslations, locale),
    startButtonName: getText(startButtonNameTranslations, locale, t("customerInfo.submit")),
    poweredBy: t("poweredBy"),
    agreement: t("customerInfo.agreement"),
    returnsPolicy: t("customerInfo.returnsPolicy"),
    returningAGift: t("customerInfo.returningAGift"),
    typeError: { loadFailed: t("typeError.loadFailed") },
  };
}

export function getNextStepPath({
  request,
  returnOrderResponse,
  orderId,
  emailOrPhone,
  locale,
  v2CustomerFlowEnabled,
  mobile,
}: {
  request: Request;
  returnOrderResponse: ReturnOrderResponse;
  orderId: string;
  emailOrPhone: string;
  locale: string;
  v2CustomerFlowEnabled: boolean;
  mobile: boolean;
}) {
  const { returnOrder, nextStep } = returnOrderResponse;

  const oldNextStepToRouteMap: Record<string, string> = {
    stageOneQuestions: "stage_one_questions",
    returnItems: "return_items_selection",
  };
  const newNextStepToRouteMap: Record<string, string> = {
    stageOneQuestions: "stage_1_questions/1",
    returnItems: "items_selection",
  };

  let nextStepPath;
  switch (returnOrder.status) {
    case "draft":
      nextStepPath = v2CustomerFlowEnabled ? newNextStepToRouteMap[nextStep] : oldNextStepToRouteMap[nextStep];
      break;
    case "pending_payment_on_submission":
      nextStepPath = "submission_payment";
      break;
    default:
      nextStepPath = v2CustomerFlowEnabled ? "summary_details" : "details";
      break;
  }

  const url = request.url;
  const shopSlug = new URL(url).pathname.split("/")[1];

  let oldCustomerAppUrl, newCustomerAppUrl;
  try {
    oldCustomerAppUrl = new URL(getPublicEnv("OLD_CUSTOMER_APP_URL") || "");
    newCustomerAppUrl = new URL(getPublicEnv("NEW_CUSTOMER_APP_URL") || "");
  } catch (error) {
    throw new Error("OLD_CUSTOMER_APP_URL or NEW_CUSTOMER_APP_URL is not set");
  }

  const appUrl = v2CustomerFlowEnabled || nextStepPath === "submission_payment" ? newCustomerAppUrl : oldCustomerAppUrl;
  const isEmbeddedFromUrl = isEmbedded(request.url);

  const { host, protocol } = appUrl;
  const hostIsLocalhost = host.includes("localhost");

  const embeddedHost = hostIsLocalhost ? `embed.${host}` : `embed-${host}`;

  // if it's mobile, we always want to redirect them to the non-embedded version of the app
  const updatedHost = isEmbeddedFromUrl && !mobile ? embeddedHost : host;
  let queryParams = new URLSearchParams({
    orderIdOrName: orderId,
    emailOrPhone,
  });

  if (nextStepPath === "submission_payment") {
    queryParams.set("locale", locale);
  } else if (!v2CustomerFlowEnabled) {
    queryParams.set("orderNameOrId", orderId);
    queryParams.set("locale", locale);
    if (mobile) queryParams.set("mobileEmbedded", "true");
  }

  const basePath = `${protocol}//${updatedHost}/${shopSlug}/returns/${returnOrder.id}`;
  const finalUrl = `${basePath}/${nextStepPath}?${queryParams.toString()}`;

  return finalUrl;
}

export function isEmbedded(url: string) {
  // example url: http://localhost:8081/nic-farm-us?embed=true or http://embed.localhost:8081/nic-farm-us
  const urlObject = new URL(url);
  // checking from search params is left here for backwards compatibility
  const isEmbeddedFromUrl = urlObject.searchParams.get("embed") === "true";
  const isEmbeddedFromSubdomain = urlObject.hostname.split(".")[0]?.includes("embed");

  return isEmbeddedFromUrl || isEmbeddedFromSubdomain;
}
